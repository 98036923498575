.sectionLabel {
    text-align: left;
    margin-top: 20px;
    margin-bottom: 0;
    color: #fa0;
    font-weight: 700;
}

.vinTextField {
    width: 100px;
}

.connectForm {
    width: 100%;
}

.MuiFormControl-root {
    width: 100%;
}

.testyyy {
    width: 100% !important;
    background-color: red !important;
}
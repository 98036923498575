.recentSalesTable {
}

.loader {
    margin-top: 20%;
    align-content: center;
    margin-left: 40%;
}

.recentSalesColumnHeader {
    background-color: red;
}

.columnHeader {
    background-color: #32383d;
    color: #fff !important;
    width: 10px !important;
}